/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:5ec63f69-5013-4aab-aea2-37f334aa2f39",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_d7IRqFQZg",
    "aws_user_pools_web_client_id": "3ve0etlo10r9182q4p24jm927d",
    "oauth": {
        "domain": "knowledgeloop-trial.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://www.knowledgeloop.jp/,https://app.knowledgeloop.jp/",
        "redirectSignOut": "http://localhost:3000/,https://www.knowledgeloop.jp/,https://app.knowledgeloop.jp/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://ssahazh3lrfl7l6zvhaxsmsqua.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://iqcx0c89k2.execute-api.ap-northeast-1.amazonaws.com/trial",
            "region": "ap-northeast-1"
        },
        {
            "name": "PayJPQueries",
            "endpoint": "https://8dzsvsj4w6.execute-api.ap-northeast-1.amazonaws.com/trial",
            "region": "ap-northeast-1"
        },
        {
            "name": "firebaseCloudMessage",
            "endpoint": "https://3s0c4xphw9.execute-api.ap-northeast-1.amazonaws.com/trial",
            "region": "ap-northeast-1"
        },
        {
            "name": "adminCreatePayment",
            "endpoint": "https://nkp3waeb4f.execute-api.ap-northeast-1.amazonaws.com/trial",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "knowledgeloopbucket130828-trial",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
